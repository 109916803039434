<template>
  <div class="content-editor">
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      class="content-editor__editor"
    />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      // starting editor's content
      content: this.value
    }
  },
  watch: {
    value(v) {
      this.content = v
    },
    content(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .content-editor {
  &__editor {
    $height: 600px;
    .tiptap-vuetify-editor__content {
      height: $height;
    }

    .tiptap-vuetify-editor__content > * {
      overflow: auto;
    }

    .tiptap-vuetify-editor__content {
      padding: 10px;

      > * {
        outline: none;
      }

      ol {
        counter-reset: item;
        list-style-type: none;

        li {
          position: relative;
          display: block;

          &:before {
            content: counter(item) '.';
            counter-increment: item;
            position: absolute;
            left: -1.55em;
          }
        }
      }

      ol ol {
        counter-reset: subitem;
        list-style-type: lower-alpha;
      }

      li li {
        margin: 0 1em;
      }

      li li:before {
        content: '(' counter(subitem, lower-alpha) ')';
        counter-increment: subitem;
        left: -2.25em;
      }

      ol ol ol {
        counter-reset: subsubitem;
        list-style-type: lower-roman;
      }

      li li li {
        margin: 0 2em;
      }

      li li li:before {
        content: '(' counter(subsubitem, lower-roman) ')';
        counter-increment: subsubitem;
        left: -2.25em;
      }
    }
  }
}
</style>
